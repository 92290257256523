import React from 'react'
import 'react-responsive-carousel/lib/styles/carousel.min.css'
import { Carousel } from 'react-responsive-carousel'
import './HeroCarousel.scss'
import placeholderImage from '../../assets/logo.svg'
import HeroBanner from '../HeroBanner'

const HeroCarousel = ({data}) => {
  return (
    <Carousel
      className="Carousel"
      showStatus={false}
      showThumbs={false}
      dynamicHeight={true}
      autoPlay={true}
      infiniteLoop={true}
    >
      {data.allWordpressWpHeroBanner.edges.map((banner, index) => {
        const bannerInfo = {
          id: banner.node.id,
          title: banner.node.title,
          caption: banner.node.acf.caption,
          image: banner.node.featured_media ? banner.node.featured_media.localFile.childImageSharp.fluid : null,
          actionURL: banner.node.acf.button_url,
          actionText: banner.node.acf.button_text,
          newWindow: banner.node.acf.new_window
        }
        return (
        <HeroBanner
        key={bannerInfo.id}
        image={bannerInfo.image}
        title={bannerInfo.title}
        caption={bannerInfo.caption}
        actionURL={bannerInfo.actionURL}
        actionText={bannerInfo.actionText}
        newWindow={bannerInfo.newWindow}
      />)
      })}
    </Carousel>
  )
}

export default HeroCarousel