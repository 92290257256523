import React from 'react'
import './index.scss'
import { graphql } from 'gatsby'
import HeroCarousel from '../components/HeroCarousel'
import Layout from '../components/Layout'
import promoVideo from '../assets/new-video.mp4'

const Home = ({ data }) => {

  return (
    <Layout>
      <div className="Home">
        <HeroCarousel data={data} />

        <div className="container content">
          <h2>OCR Academy is Ottawa's first and only obstacle training gym.</h2>
          <div className="promoVideo">
            <video controls>
              <source src={promoVideo} type="video/mp4" />
            </video>
          </div>
          <p>
            <strong>OCR Academy is about fun.</strong> Remember running,
            climbing, jumping and playing as a kid? You were getting TONS of
            awesome exercise - and it didn't even feel like it, did it? We want
            you to run, jump, play - and feel like that again!
          </p>

          <p>
            <strong>OCR Academy is about fitness.</strong> When we're fit, we're
            ready to meet each day and fulfill our responsibilities with focus
            and energy. When we're fit, not only do we survive - we thrive.
            Fitness is important for our bodies, for our minds, and for how we
            meet each day.{' '}
          </p>

          <p>
            <strong>OCR Academy is about life.</strong> Physical fitness is
            integral to a healthy life. That includes being active, building
            mental and physical resiliency, and making healthy food choices. We
            work with you to build all that. We even have nutrient-dense food
            choices - like fresh shotgun coffee, sparkling coconut water,
            Kombucha, and protein-packed energy bars - at Goatberry Cafe for
            you. You know, just in case you get peckish post-workout.
          </p>
        </div>
      </div>
    </Layout>
  )
}

export default Home

export const query = graphql`
  query {
    allWordpressWpHeroBanner(sort: { order: ASC, fields: order }) {
      edges {
        node {
          acf {
            button_text
            button_url
            caption
            new_window
          }
          title
          id
          featured_media {
            localFile {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`
